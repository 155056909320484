*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Tahoma', sans-serif;
}

.container-uploadsys{
    min-width: 50%;
    min-height: 30vh;
    /* background-color: #f36421; */
    background-color: #00529c;
    text-align: center;
    color: #ffff;
    /* position: absolute; */
}

#image-input{
    border-radius: 10px;
    border: solid,2px,#ffff;
    padding: 2px;
    margin: 2px;
}

#upload-btn{
    height: 30px;
    width: 88px;
    background-color: #f36421;
    color: #ffff;
    border-radius: 7px;
    border: #ffff 2px;
    padding: 2px;
    font-weight: bold;
    margin: 5px;
    
}

#btn{
    
    background-color: #f36421;
    color: #ffff;
    border-radius: 7px;
    border: #ffff 2px;
    padding: 5px;
    /* font-weight: bold; */
    margin: 5px;
    
}

#btn:hover{
    opacity: 0.7;
}



#upload-btn:hover{
    opacity: 0.7;
}

.show-button:hover{
    opacity: 0.7;
}


.show-button{
    height: 30px;
    width: 135px;
    background-color: #f36421;
    color: #ffff;
    border-radius: 7px;
    border: #ffff 2px;
    padding: 2px;
    font-weight: bold;
   
}

/*.uploaded-images{
    background-color: #f36421;

}

.images-container{
    width: 160px;
    height: 180px;
    margin: 5px;
    border-collapse: collapse;
}

img{
    width: 150px;
    height: 150px;
    padding: 2px;
    border: solid 2px #ffff;
}

img:hover{
    opacity: 0.6;
}
*/

.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }
  
  .images-container {
    position: relative;
  }
  
  .gallery-image {
    width: 150px;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out; /* Add a smooth transition */
  }
  
  .gallery-image:hover {
    opacity: 0.7; /* Reduce opacity on hover */
  }


 .footer {
        background-color: #f36d2d ; 
        color: white;
        text-align: center;
        padding-top: 15px;
    }

    .footer-inner {
        background-color: #00529c ; 
        padding: 10px 0; 
    }

    .footer p {
        margin: 0; 
        font-size: 24px; 
        font-weight: bold; 
    }  
